import React, { useEffect } from "react";

import {  Box, Button,  CircularProgress, Dialog, DialogTitle } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Root } from "./shared";
import TermsOfService from "./termsOfService";
import { useAppContext } from "../app/appContext";


const LandingCheck: React.FC = () => {
    const [acceptTos, setAcceptTos] = React.useState(false);
    const [profileLoading, setProfileLoading] = React.useState<boolean>(true);
    const navigate = useNavigate();
    const {isphone, profile, isAppLoading, setProfile, setCallback}=useAppContext();
    const handleAcceptTos = () => {
        setAcceptTos(true);
    };

    useEffect(()=>{
        setCallback(() => navTo)
    },[])

    useEffect(() => {
        if (profile) {
           debugger
            setProfileLoading(false);
            if (!profile.is_tos_accepted && acceptTos) {
                const newProfile = { ...profile, is_tos_accepted: true };
                setProfile(newProfile);
            } else if (profile.is_tos_accepted) {
                navTo();
            }
        } else {
            console.log('profile does not exist')
            debugger;
            navTo();
        }
    }, [acceptTos, profile]);

    function navTo() {
        console.log('nav callback triggered')
        if (profile?.is_first_run && profile?.is_tos_accepted) {
            console.log('navigating to get started')
            navigate('../get-started');
        }
        else if (!profile?.is_first_run && profile?.is_tos_accepted) {
            console.log('navigating to chat')
            navigate('../chat');
        }
    }

    return (
        <Root>
    {profileLoading? <CircularProgress />:
    <Dialog open={!acceptTos} 
    onClose={() => {}} 
    disableEscapeKeyDown 
    PaperProps={{
        sx: {
            width: "90%",
            height: "90vh",
            maxWidth: "100%",
            maxHeight: "none",
          },
            }}>
        <DialogTitle>
        Please review and accept the Terms of Service & Privacy Policy
        </DialogTitle>
           <TermsOfService />
           <Box  sx={{
            maxWidth: isphone? '250px': '500px',
        display: 'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent: 'center',
        margin: '20px',
    }}>
        <Button variant='contained'
        onClick={handleAcceptTos}
        >
            Accept Terms of Service and Privacy Policy
        </Button>
        </Box>
    </Dialog>
    }

        </Root>);
};

export default LandingCheck;