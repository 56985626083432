import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Root, LeftNav, Panel, RootRight } from '../app/shared';
import { useMediaQuery, Grid, Box, Typography, CircularProgress} from '@mui/material';
import { mediaSize, gridSize } from '../fre/shared';
import { SideMenu } from '../app/leftNav';
import ResourceLink from './resourceLink';
import LogoutButton from '../app/logout';
import { Bookmarks } from '../../api/resources/fetchResources';
import happy_hat from '../../assets/images/happy_hat.png';
import AppContainer from '../app/appContainer'; 
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import { ResourceEmptyContainer, ResourceEmptyTextContainer } from './shared';
import { useAppContext } from '../app/appContext';
import { handleApiError, MUIError } from '../../api/apiErrorHandler';
import serviceInstance from '../../config/serviceInstance';
const Resources: React.FC = () => {
  const [selectedMenuItem, setSelectedMenuItem] = React.useState<string>("");
  const [resources, setResources] = useState<Bookmarks[] | null>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const {isphone, setIsError, setErrMessage} = useAppContext();

  const handleLinkClick = (link: string) => {
    window.open(link, "_blank"); // Open the link in a new tab
  };

  const axiosInstance = serviceInstance();

  const FetchResourcePages = async (): Promise<Bookmarks[] | null> => {

    try {
      const response = await axiosInstance.get("/bookmarks");
  
      if (response.status === 204) {
        console.log('No resources data available.');
        return null;
      }
  
      // Map the API response to the ResourcePagesItems interface
      const resources: Bookmarks[] = response.data.map((item: any) => ({
        image_link: item.image_link,
        page_url: item.page_url,
        page_friendly_name: item.page_friendly_name,
        page_description: item.page_description             
    }));
  
      if (resources && Array.isArray(resources)) {
        console.log(`Got response from resources API: ${JSON.stringify(resources)}`);
        return resources;
      } else {
        console.error('Unexpected response format:', response);
        throw new Error('Unexpected response format');
      }
  
    } catch (error: any) {
      throw handleApiError(error)
  }
  }
  

  const getResourcePages = async () => {
    try{
      const response = await FetchResourcePages();
      setLoading(false);
      response && setResources(response)
    }
    catch (error: any){
      setIsError(true)
      setErrMessage(error.message)

    }  
  }

  useEffect(() => {
    if (!resources) {
      getResourcePages();
    };
  }, []);


  return (
    <AppContainer>
        <Grid container>
          {resources && resources.length>0? (
            resources.map((resource, index) => (
              <Grid item xs={12} md={4} key={index}>
                <ResourceLink
                  title={resource.page_friendly_name || ''}
                  description={resource.page_description}
                  image_url={resource.image_link}
                  link={resource.page_url}
                  onClick={handleLinkClick}
                />
              </Grid>
            ))
          ) : isLoading? <CircularProgress></CircularProgress>: (
            <ResourceEmptyContainer id='empty-resources-container' isphone={isphone}>
            <img src={happy_hat} alt="Celebration" height="300px" />
            <ResourceEmptyTextContainer id='empty-resource-text-container'>
          <Typography variant="h3" textAlign='left' color={
                'black' } sx={{padding: isphone? '20px' : ''}}>
                You have not bookmarked any resources yet.
              </Typography>
              <Box height='20px'></Box>
              <Typography variant="body1" textAlign='left' color={
                'black' } sx={{padding: isphone? '20px' : ''}}>
                Use the bookmark icon <BookmarkAddIcon /> at the bottom of my chat messages in the chat screen to add resources to this page! 
              </Typography>
              </ResourceEmptyTextContainer>
            
          </ResourceEmptyContainer>
          )}
        </Grid>
  </AppContainer>
  );
};

export default Resources;