import React, {useState, ChangeEvent} from "react";

import { Button, Box, Typography, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Checkbox, ListItemText, TextField } from "@mui/material";
import { ReviewBoxContainer, ReviewBoxHeader} from "../../shared";
import ClickAwayListener from 'react-click-away-listener';
import { Timestamp } from "../../../../model/messages/messages";
import { useAppContext } from "../../../app/appContext";
import {FeedbackData} from "../../../../api/chat/sendFeedback";
import { handleApiError, MUIError } from "../../../../api/apiErrorHandler";
import serviceInstance from "../../../../config/serviceInstance";
interface RatingBoxProps {
    handleClose: () => void;
    messageTimestamp?: number;
    messageObjectId: string;
  }

export const ThumbsDownReview: React.FC<RatingBoxProps> = ({ 
    handleClose,
    messageTimestamp,
    messageObjectId,
}) => {

const [selectedItems, setSelectedItems] = useState<string[]>([]); 
const [comments, setComments] = useState<string>('');
const {isError, setErrMessage, setIsError, setErrSev} = useAppContext();
const axiosInstance = serviceInstance();

const sendFeedback = async (feedbackData: FeedbackData) => {
  console.log(`sending response feedback with data: ${JSON.stringify(feedbackData)}`)
  //debugger;
  try {

    const response = await axiosInstance.post('/feedback',  {
      ...feedbackData
    });
    if (response.status === 200) {
      return ;
    } else {
      console.error('Unexpected response format:', response);
      throw new Error('Unexpected response format');
    }
  } catch (error: any) {
    throw handleApiError(error)
}
}


const handleSendFeedback = async (newFeedback: FeedbackData) => {
  try{
   await sendFeedback(newFeedback);
  }
  catch (error: any){
    if (error instanceof MUIError) {
    setErrSev(error.severityLevel || 'error')
    setIsError(true)
    setErrMessage(error.message)
} else {
  setIsError(true)
  setErrMessage("Unknown Error saving bookmarks")
}
}
}

const handleCommentsChange = (event: ChangeEvent<HTMLInputElement>) => {
   setComments(event.target.value);
}

const handleSubmit = (selectedItems: string[], comments: string) => {
    //console.log(`Selected Items: ${selectedItems}`);
    //console.log(`Comments: ${comments}`);
    const newFeedback: FeedbackData = {
        rating_reasons: selectedItems,
        comments,
        date_rated: messageTimestamp || 0,
        sentiment: 'negative',
        object_id: messageObjectId,
    };
    handleSendFeedback(newFeedback);
    handleClose();
};

const handleChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedItems(event.target.value as string[]);
  };

const options: string[] = ['Response was not helpful', 'Response was incorrect', 'Response was incomplete', 'Response was confusing', 'Took too long', 'Other'];


  return (
    <ClickAwayListener onClickAway={handleClose}>
    <ReviewBoxContainer>
    <ReviewBoxHeader>
      <Typography>
          Uh oh!  How can we do better?
      </Typography>
      </ReviewBoxHeader>
      
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="multi-select-label">Select All That Apply</InputLabel>
        <Select
          labelId="multi-select-label"
          multiple
          value={selectedItems}
          onChange={handleChange}
          renderValue={(selected) => (selected as string[]).join(', ')} // Display selected items
          MenuProps={{
            disablePortal: true,
           }}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={selectedItems.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box height='20px' />
      <Typography>Please Add Comments to Help us Improve:</Typography>
      <TextField onChange={handleCommentsChange} value={comments} multiline rows={4} fullWidth>
      </TextField>
      <Box height='20px' />
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '20px' }}>
       
    <Button variant="contained" color="primary" onClick={() => handleSubmit(selectedItems, comments)}>
        Submit
    </Button>
    <Button variant="contained" color="secondary" onClick={handleClose}>
        Cancel
    </Button>
    </Box>
  </ReviewBoxContainer>
  </ClickAwayListener>
  );
};

export default ThumbsDownReview

