import {Auth0Provider} from '@auth0/auth0-react';
import {useAuth0} from '@auth0/auth0-react';
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import history from "./utils/history";

const Auth0ProviderWithNavigate = ({children}) => {

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  let redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
  console.log(`auth provider wrapper mounted`)
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const onRedirectCallback = (appState) => {
    history.push(
        appState && appState.returnTo ? appState.returnTo : window.location.pathname
    );
  };

  if (!(domain && clientId && redirectUri && audience)) {
    console.log(`Auth0ProviderWithNavigate: 
    Missing configuration. ${domain} ${clientId} ${redirectUri} ${audience}`);
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: audience,
        scope: `user openid`,

      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {/* <TokenFetcher>{children}</TokenFetcher> */}
      {children}
    </Auth0Provider>
  );
};

Auth0ProviderWithNavigate.propTypes = {
  children: PropTypes.node.isRequired,
};



const TokenFetcher = ({children}) => {
  const {isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup} = useAuth0();

// no longer needed in the new sdk - refresh tokens handle this on teh server side
  // useEffect(() => {
  //   const fetchToken = async () => {

  //     const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  //     const popup = async () => {
  //       try {
  //         const token = await getAccessTokenWithPopup({
  //           authorizationParams: {
  //             audience: audience,
  //             scope: 'user openid',
  //           },
  //         });
  //         sessionStorage.setItem('accessToken', token);
  //         console.log('getAccessTokenWithPopup: able to fetch token');
  //       } catch (err) {
  //         console.log('getAccessTokenWithPopup - err: ', err);
  //       }
  //     };

  //     try {
  //       const token = await getAccessTokenSilently({
  //         authorizationParams: {
  //           audience: audience,
  //           scope: `user, openid`,
  //         },
  //       });
  //       sessionStorage.setItem('accessToken', token);
  //       console.log('able to fetch token');
  //     } catch (err) {
  //       console.log(`Unable to get access token for user: ${err.message}`);
  //       await popup();
  //     }
  //   };

  //   console.log('use effect in auth provider triggered to fetch token');
  //   if(isAuthenticated) {
  //     fetchToken();
  //   }
  // }, [isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup]);
  return <>{children}</>;
};

TokenFetcher.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Auth0ProviderWithNavigate;
