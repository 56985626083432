import React, { useEffect, useState } from 'react';
import { useMediaQuery,  CircularProgress} from '@mui/material';
import FetchResourceCategories, { ResourceList } from '../../api/resources/fetchResourceCategories';
import AppContainer from '../app/appContainer';
import LandingDesktop from './landingDesktop';
import LandingMobile from './landingMobile';
import { useAppContext } from '../app/appContext';
import { handleApiError } from '../../api/apiErrorHandler';
import serviceInstance from '../../config/serviceInstance';
const Landing: React.FC = () => {

    const [resources, setResources] = useState<ResourceList[]>();
    const {isphone} = useAppContext();
    const axiosInstance = serviceInstance();

    const FetchResourceCategories = async (): Promise<ResourceList[] | null> => {
        const requiredCategories = ['financial', 'academic', 'career', 'wellbeing'];
        
        try {
      
      
          const response = await axiosInstance.get('/bookmark-categories');
      
          if (response.status === 204) {
            console.log('No resources data available.');
            return null;
          }
          if (response.data && Array.isArray(response.data)) {
            console.log(`Got response from resources API: ${JSON.stringify(response.data)}`);
            // Create a map of existing categories from the response
            const existingCategories = response.data.reduce((acc: any, item: ResourceList) => {
              acc[item.category] = item;
              return acc;
            }, {});
      
            // Loop through the required categories and add missing ones
            const finalCategories = requiredCategories.map((category) => {
              if (existingCategories[category]) {
                return existingCategories[category];
              } else {
                // Add missing category with an empty pages list
                return { category, pages: [] };
              }
            });
      
          return finalCategories; // Return the final array with all required categories
      }
          throw new Error('unable to parse response data')
        } catch (error: any) {
          throw handleApiError(error)
      }
      }
    

    const getResources = async () => {
        const response = await FetchResourceCategories();
        //console.log(`response in component: ${JSON.stringify(response)}`)
        if (Array.isArray(response)) {
            //console.log(`setting response items`)
            setResources(response);
        } else {
            console.error("Response is not an array");
        }
    };

    useEffect(() => {
        //console.log(`resources use effect ${JSON.stringify(resources)}`)
    }, [resources])

    useEffect(() => {
        getResources();
    }, [])

    return (
        <AppContainer>
            {resources ?
                !isphone ?
                    <LandingDesktop resources={resources} /> :
                    <LandingMobile resources={resources}/> :
                    <CircularProgress/>
                }
        </AppContainer>
    );
};

export default Landing;