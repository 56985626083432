import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { BaseUrl } from '../api/baseURL';

const serviceInstance = () => {
    const { loginWithRedirect, getAccessTokenSilently } = useAuth0();

    const axiosInstance = axios.create({
        baseURL: BaseUrl(),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    // Request Interceptor
    axiosInstance.interceptors.request.use(
        async (config) => {
            const token = await getAccessTokenSilently()
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    // Response Interceptor
    axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (error.response?.status === 401) {
                try {
                    // Try to get a new token silently
                    const newToken = await getAccessTokenSilently();

                    // Retry the original request with the new token
                    error.config.headers['Authorization'] = `Bearer ${newToken}`;
                    return axiosInstance.request(error.config);
                } catch (authError) {
                    console.warn('Silent authentication failed:', authError);

                    // If silent authentication fails, redirect to log-in
                    await loginWithRedirect();
                }
            }
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

export default serviceInstance;
