import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import bg_img from '../assets/images/landing_bg.png';
import '@fontsource/league-spartan';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ExploreIcon from '@mui/icons-material/Explore'; 
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import AssistantIcon from '@mui/icons-material/Assistant';
import bgVideo from '../assets/videos/bg_vid.mp4';
import { useMediaQuery } from '@mui/system';
import HomePageMobile from './homeMobile';
const Landing = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '100vh',
  overflow: 'hidden', // To ensure the video doesn't overflow
});

const BackgroundVideo = styled('video')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover', // Ensures the video covers the entire background
  zIndex: -100, // To place the video behind other elements
});

const MidBar = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(65),
  left: 0,
  right: 0,
    backgroundColor: 'rgba(51, 145, 173, .9)', // Blue color with 70% opacity
  width:'100%',
  display: 'flex',
  fontsize: '2rem',
  height: '250px',
  justifyContent: 'space-between',
  alignItems: 'center',
}));



const Title = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(40),
    left: theme.spacing(1), // Align to the right
    color: 'white',
    fontFamily: 'League Spartan', // Apply League Spartan font
    fontWeight: 'bold',
    fontSize: '4rem', // Make the text much bigger
    textAlign: 'left', // Right-align the text
    wordWrap: 'break-word', // Enable text wrapping
    maxWidth: '90%', // Optional: Limit the width to help with wrapping
  }));


const HomePage: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();
  const isphone = useMediaQuery('(max-width:800px)');
  const version = process.env.REACT_APP_VERSION
  const goToLogin = () => {
      navigate('../app/landing-check'); 
  };

  useEffect(() => {
    console.log(`isAuthenticated: ${isAuthenticated}, isLoading: ${isLoading}`);
    if (isAuthenticated && !isLoading) {
      console.log(`user is already logged in.  Going to app`)
    goToLogin();
    }
    else {
      console.log('user is not logged in')
    }
  }, [isAuthenticated, isLoading]);

  return (
    <Landing>
      <BackgroundVideo autoPlay loop muted playsInline>
        <source src={bgVideo} type="video/mp4" />
        </BackgroundVideo>
        {isphone? <HomePageMobile/> :
        <Box>
        <Button
          variant="contained"
          sx={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            backgroundColor: '#FFDD57', // Yellow button color
            color: 'black', // Black text color
            fontWeight: 'bold',
            fontSize: '1.2rem', // Make the text bigger
            padding: '12px 24px', // Make the button bigger
            marginRight: '20px', // Add margin to the right
            '&:hover': {
              backgroundColor: '#FFC107', // Slightly darker yellow on hover
            },
          }}
          onClick={goToLogin}
        >
          Get Started
        </Button>
      <Title>
      Your AI Guide to colleges and careers in Washington State
      </Title>
      <MidBar>
        <Box display={'flex'} flexDirection={'row'} width='100%' sx={{justifyContent: 'space-between', margin: '50px'}}>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <ExploreIcon sx={{fontSize: '100px', marginBottom: '20px', color: '#fff'}} />
          <Typography variant='h3'sx={{fontSize: '2rem'}}>Choose Your Path</Typography>
          </Box>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <VolunteerActivismIcon sx={{fontSize: '100px', marginBottom: '20px', color: '#fff'}} />
          <Typography variant='h3' sx={{fontSize: '2rem'}}>Get the Help You Need</Typography>
          </Box>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <AssistantIcon sx={{fontSize: '100px', marginBottom: '20px', color: '#fff'}} />
          <Typography variant='h3' sx={{fontSize: '2rem'}}>Chat With Your Guide</Typography>
          </Box>
        </Box>
      </MidBar>
      <Typography variant='body1' textAlign={'left'} sx={{position: 'absolute', bottom: '10px', left: '10px'}}>
        Powered by Campus Evolve &nbsp; {version}
      </Typography>
      </Box>
}
    </Landing>
  );
};

export default HomePage;